import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ColorSelectItem from '@/storychief/components/ColorSelectItem';
import { COLOR_NONE } from '@/storychief/constants/Constants';

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  value: PropTypes.number,
  variant: PropTypes.oneOf(['default', 'sm']),
  onChange: PropTypes.func,
};
const defaultProps = {
  value: null,
  variant: 'default',
  onChange: () => {},
};

function ColorSelect({ value, options, variant, onChange }) {
  const handleChange = useCallback(
    (e) => {
      const changedValue = Number(e.target.value);
      onChange(changedValue !== COLOR_NONE ? changedValue : null);
    },
    [onChange],
  );

  function getValue() {
    return value === null ? COLOR_NONE : value;
  }

  return (
    <div className={classNames('color-select', `color-select--${variant}`)}>
      <ColorSelectItem
        value={COLOR_NONE}
        label="None"
        selectedValue={getValue()}
        onChange={handleChange}
      />

      {options.map((id, index) => (
        <ColorSelectItem
          key={id}
          value={id}
          label={`Color ${index + 1}`}
          selectedValue={getValue()}
          onChange={handleChange}
        />
      ))}
    </div>
  );
}

ColorSelect.propTypes = propTypes;
ColorSelect.defaultProps = defaultProps;

export default ColorSelect;
