/**
 * Return an object of type CustomFieldValue by passing in an object of type CustomFieldOption.
 * @param {object} option
 * @param {string} option.custom_field_id
 * @param {string} option.value
 * @param {string} option.label
 * @param {number} option.color
 * @returns {{__typename: "CustomFieldValue", custom_field_id: string, label: string, value: string}}
 */
export default function getCustomFieldOptionValue(option) {
  return {
    __typename: 'CustomFieldValue',
    custom_field_id: option.custom_field_id,
    value: option.value,
    label: option.label,
    color: option.color,
  };
}
