import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import { Overlay, Popover } from 'react-bootstrap';
import convertTextToSlug from '@/storychief/utils/convertTextToSlug';
import { COLOR_NONE } from '@/storychief/constants/Constants';
import SelectCreateNewOptionsItem from '@/storychief/components/SelectCreateNewOptionsItem';
import Button from '@/storychief/components/Button';

const propTypes = {
  existingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
  isShow: PropTypes.bool,
  defaultLabels: PropTypes.arrayOf(PropTypes.string),
  onCreate: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};
const defaultProps = {
  existingOptions: [],
  isShow: false,
  defaultLabels: null,
};

function SelectCreateNewOptions({ defaultLabels, isShow, existingOptions, onCreate, onHide }) {
  // State
  const [options, setOptions] = useState([]);
  const [invalidOptions, setInvalidOptions] = useState([]);
  const [isAdvanced, setIsAdvanced] = useState(false);

  useEffect(() => {
    if (isShow) {
      setOptions(
        defaultLabels.map((defaultLabel) => ({
          id: `${uniqueId()}`,
          label: defaultLabel || '',
          value: convertTextToSlug(defaultLabel || ''),
          color: COLOR_NONE,
        })),
      );
    }
  }, [isShow]);

  // Refs
  const containerRef = useRef(null);

  // Variables
  const containerWidth = containerRef?.current?.getBoundingClientRect().width || 0;
  const selectColorPopoverId = 'create-new-option-color-select';
  const isFormValid = options.every((o) => o.label !== '' && o.value !== '');

  // Functions
  function formReset() {
    setInvalidOptions([]);
  }

  function handleOnToggleAdvanced() {
    setIsAdvanced(!isAdvanced);
  }

  function handleOnHide(e) {
    const isClickedInsideSelectColorPopover = e?.target.closest(`#${selectColorPopoverId}`);

    if (!isClickedInsideSelectColorPopover) {
      onHide();
    }
  }

  function handleOnExited() {
    formReset();
  }

  function getInvalidOptions() {
    return options.filter(({ value }) => existingOptions.some((o) => o.value === value));
  }

  function handleOnOptionChange(changedOption) {
    const newOptions = options.map((option) => {
      if (option.id === changedOption.id) {
        return changedOption;
      }

      return option;
    });

    setInvalidOptions((currentInvalidOptions) =>
      currentInvalidOptions.filter((o) => o.id !== changedOption.id),
    );
    setOptions(newOptions);
  }

  function handleOnSubmit(e) {
    e.preventDefault(); // Prevent full page (classic) form submission
    e.stopPropagation(); // Prevent parent form submission

    if (getInvalidOptions().length > 0) {
      setInvalidOptions(getInvalidOptions());
      return;
    }

    if (isFormValid) {
      onCreate(
        options.map(({ value, label, color }) => ({
          value,
          label,
          color: color !== COLOR_NONE ? color : null,
        })),
      );
      handleOnHide();
    }
  }

  // Render
  return (
    <div className="position-relative" ref={containerRef}>
      <Overlay
        show={isShow}
        shouldUpdatePosition
        target={containerRef.current}
        container={containerRef.current}
        placement="bottom"
        onHide={handleOnHide}
        onExited={handleOnExited}
        rootClose
      >
        <Popover
          id="create-new-option-color"
          style={{
            width: containerWidth,
            maxWidth: containerWidth,
          }}
        >
          <form onSubmit={handleOnSubmit}>
            {options.map((option) => (
              <SelectCreateNewOptionsItem
                key={option.id}
                id={option.id}
                label={option.label}
                value={option.value}
                color={option.color}
                isAdvanced={isAdvanced}
                isInvalid={invalidOptions.some((o) => o.id === option.id)}
                onChange={handleOnOptionChange}
              />
            ))}

            <div className="flex gap-2">
              <Button
                type="submit"
                size="sm"
                disabled={!isFormValid}
                data-testid="cf-select-create-new-option-button-submit"
              >
                Create
              </Button>

              <Button size="sm" variant="secondary" onClick={handleOnHide}>
                Cancel
              </Button>
              <Button variant="link" size="sm" onClick={handleOnToggleAdvanced} className="ml-auto">
                {isAdvanced ? 'Hide' : 'Show'} advanced
              </Button>
            </div>
          </form>
        </Popover>
      </Overlay>
    </div>
  );
}

SelectCreateNewOptions.propTypes = propTypes;
SelectCreateNewOptions.defaultProps = defaultProps;

export default SelectCreateNewOptions;
