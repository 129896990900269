import { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl, Overlay, Popover } from 'react-bootstrap';
import { COLOR_CODING } from '@/storychief/constants/Constants';
import ColorSelect from '@/storychief/components/ColorSelect';
import ColorIndicator from '@/storychief/components/ColorIndicator';
import convertTextToSlug from '@/storychief/utils/convertTextToSlug';

const propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.number,
  isAdvanced: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
const defaultProps = {
  color: null,
  isInvalid: false,
  isAdvanced: false,
};

function SelectCreateNewOptionsItem({ id, value, label, color, isAdvanced, isInvalid, onChange }) {
  // State
  const [isValueDirty, setIsValueDirty] = useState(false);
  const [isShowSelectColor, setIsShowSelectColor] = useState(false);

  // Refs
  const colorContainerRef = useRef(null);
  const labelRef = useRef(null);

  // Variables
  const colorOptions = useMemo(() => Object.values(COLOR_CODING), []);
  const selectColorPopoverId = 'create-new-option-color-select';

  // Functions
  function handleOnValueChange(e) {
    onChange({
      id,
      value: e.target.value,
      label,
      color,
    });
    setIsValueDirty(!!e.target.value && e.target.value !== convertTextToSlug(label));
  }

  function handleOnLabelChange(e) {
    onChange({
      id,
      value: !isValueDirty ? convertTextToSlug(e.target.value) : value,
      label: e.target.value,
      color,
    });
  }

  function handleOnColorChange(newColor) {
    onChange({
      id,
      value,
      label,
      color: newColor,
    });
    setIsShowSelectColor(false);

    if (labelRef.current) {
      labelRef.current.focus();
    }
  }

  function handleOnShowSelectColor() {
    setIsShowSelectColor(true);
  }

  function handleOnHideSelectColor() {
    setIsShowSelectColor(false);
  }

  // Render
  return (
    <>
      <div className="custom-fields-options-config__row">
        <div
          className="custom-fields-options-config__col custom-fields-options-config__col--color"
          ref={colorContainerRef}
        >
          <div className="control-label m-0">&nbsp;</div>
          <button type="button" className="btn btn-chromeless" onClick={handleOnShowSelectColor}>
            <ColorIndicator isInteractive color={color} />
          </button>

          <Overlay
            show={isShowSelectColor}
            onHide={handleOnHideSelectColor}
            rootClose
            trigger={['click']}
            placement="bottom"
            target={colorContainerRef.current}
          >
            <Popover id={selectColorPopoverId}>
              <ColorSelect
                value={color}
                options={colorOptions}
                variant="sm"
                onChange={handleOnColorChange}
              />
            </Popover>
          </Overlay>
        </div>

        <div className="custom-fields-options-config__col custom-fields-options-config__col--label">
          <FormGroup
            controlId="label"
            className="m-0"
            validationState={isInvalid && !isAdvanced ? 'error' : null}
          >
            <ControlLabel className="m-0">Label *</ControlLabel>
            <FormControl
              type="text"
              className="input-sm"
              value={label}
              onChange={handleOnLabelChange}
              placeholder="Label"
              autoFocus
              inputRef={(ref) => {
                labelRef.current = ref;
              }}
            />
          </FormGroup>
        </div>
        {isAdvanced && (
          <div className="custom-fields-options-config__col custom-fields-options-config__col--value">
            <FormGroup
              controlId="value"
              className="m-0"
              validationState={isInvalid ? 'error' : null}
            >
              <ControlLabel className="m-0">Value *</ControlLabel>
              <FormControl
                type="text"
                className="input-sm"
                value={value}
                onChange={handleOnValueChange}
                placeholder="Value"
              />
            </FormGroup>
          </div>
        )}
      </div>

      {!!isInvalid && (
        <div className="small text-danger space-1">
          An option with the value &quot;{value}&quot; already exists.
        </div>
      )}
    </>
  );
}

SelectCreateNewOptionsItem.propTypes = propTypes;
SelectCreateNewOptionsItem.defaultProps = defaultProps;

export default SelectCreateNewOptionsItem;
