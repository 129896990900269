import PropTypes from 'prop-types';
import { components } from 'react-select';

const propTypes = {
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string,
    onCreateOption: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onMenuClose: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};
const defaultProps = {};

function SelectColorMenuList(props) {
  function handleCreateNewOption() {
    props.selectProps.onCreateOption(props.selectProps.inputValue || '');
    props.selectProps.onInputChange('');
    props.selectProps.onMenuClose();
  }

  return (
    <components.MenuList {...props}>
      <div className="react-select__menu-item--create">
        <button
          type="button"
          className="btn btn-link btn-chromeless"
          onClick={handleCreateNewOption}
        >
          Create new option
        </button>
        <span> or enter an option label and hit enter...</span>
      </div>

      {props.children}
    </components.MenuList>
  );
}

SelectColorMenuList.propTypes = propTypes;
SelectColorMenuList.defaultProps = defaultProps;

export default SelectColorMenuList;
