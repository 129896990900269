import PropTypes from 'prop-types';
import LabelListFieldContent from '@/stories/components/datatables/fields/fieldsComponents/LabelListFieldContent';

const propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.number,
    }),
  ).isRequired,
};
const defaultProps = {};

function CustomFieldValueLabels({ values }) {
  if (values.length > 0) {
    const data = values.map((option, index) => ({
      id: index,
      name: option.label,
      color: option.color,
    }));

    return <LabelListFieldContent type="label" data={data} show={2} />;
  }

  return '-';
}

CustomFieldValueLabels.propTypes = propTypes;
CustomFieldValueLabels.defaultProps = defaultProps;

export default CustomFieldValueLabels;
