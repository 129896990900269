import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import { Icon } from '@iconify-icon/react';

const propTypes = {
  value: PropTypes.number.isRequired,
  selectedValue: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
const defaultProps = {};

function ColorSelectItem({ value, selectedValue, label, onChange }) {
  const fieldId = useMemo(() => uniqueId('color-select-'), []);
  const inputId = `color-input-${fieldId}-${value}`;
  return (
    <label className="color-select__item" htmlFor={inputId} title={label}>
      <input
        className="color-select__input sr-only"
        type="radio"
        name={fieldId}
        id={inputId}
        value={value}
        checked={value === selectedValue}
        onChange={onChange}
      />
      <div className={`color-select__indicator color-coding--${value}`}>
        <Icon icon="fa:check" className="iconify-icon text-xxs" />
      </div>
      <span className="sr-only">{label}</span>
    </label>
  );
}

ColorSelectItem.propTypes = propTypes;
ColorSelectItem.defaultProps = defaultProps;

export default ColorSelectItem;
