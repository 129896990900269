/* eslint-disable react/no-array-index-key */
import { useEffect, useMemo, useState } from 'react';
import { gql, useLazyQuery, useMutation, useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import Select from '@/storychief/components/Select';
import { useDebounce, useDidUpdateEffect, usePrevious } from '@/storychief/hooks';
import getCustomFieldOptionValue from '@/storychief/utils/getCustomFieldOptionValue';
import SelectColorOption from '@/storychief/components/SelectColorOption';
import SelectColorMultiValueContainer from '@/storychief/components/SelectColorMultiValueContainer';
import SelectColorSingleValue from '@/storychief/components/SelectColorSingleValue';
import SelectCreateNewOptions from '@/storychief/components/SelectCreateNewOptions';
import SelectColorMenuList from '@/storychief/components/SelectColorMenuList';
import CustomFieldValueLabels from '@/storychief/components/custom-fields/CustomFieldValueLabels';

const propTypes = {
  display: PropTypes.oneOf(['field', 'fieldPreview', 'fieldValue', 'datatable']),
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  fieldDefinition: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    multi: PropTypes.bool,
    important: PropTypes.bool,
    description: PropTypes.string,
    default: PropTypes.string,
    status: PropTypes.string,
    dynamic_options: PropTypes.bool,
    option_src: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        color: PropTypes.number,
      }),
    ),
  }).isRequired,
  fieldValue: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  parentValue: PropTypes.shape({
    custom_field_id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  onUpdateCallback: PropTypes.func,
  onLoading: PropTypes.func,
  language: PropTypes.string,
  isDebounced: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  visible: true,
  display: 'field',
  language: null,
  parentValue: null,
  onUpdateCallback: () => null,
  onLoading: () => {},
  isDebounced: false,
};

const QUERY_CUSTOM_FIELD_OPTIONS = gql`
  query CustomFieldOptions($input: CustomFieldFetchOptions!) {
    options: fetchAccountCustomFieldOptions(input: $input) {
      __typename
      custom_field_id
      value
      label
    }
  }
`;

const MUTATION_UPSERT_CUSTOM_FIELD_OPTION = gql`
  mutation UpsertCustomFieldOption($input: UpsertCustomFieldOptionInput!) {
    custom_field: upsertCustomFieldOption(input: $input) {
      __typename
      options {
        id
        custom_field_id
        value
        label
        color
      }
    }
  }
`;

function SelectFieldType(props) {
  // Variables
  const {
    fieldDefinition,
    disabled,
    visible,
    display,
    fieldValue,
    parentValue,
    language,
    isDebounced,
    onLoading,
  } = props;
  const selectComponents = useMemo(
    () => ({
      Option: SelectColorOption,
      MultiValueContainer: SelectColorMultiValueContainer,
      SingleValue: SelectColorSingleValue,
      MenuList: SelectColorMenuList,
    }),
    [],
  );

  // State
  const [options, setOptions] = useState(fieldDefinition.options || []);
  const [values, setValues] = useState(fieldValue.value || []);
  const [createOptions, setCreateOptions] = useState([]);
  const createNewOptions = createOptions.filter((o) => o.__isNew__);

  // Queries
  const [getCustomFieldOptions, { loading: isCustomFieldOptionsLoading }] = useLazyQuery(
    QUERY_CUSTOM_FIELD_OPTIONS,
    {
      onCompleted: (response) => {
        onLoading(false);
        setOptions(response.options);

        if (values.some((v) => !v.label)) {
          // Sometimes the label is null, because it was added by using the public-API.
          setValues(
            values.map((v) => ({
              ...v,
              // Fix the labels of the current selection
              ...{ label: response.options.find((o) => o.value === v.value)?.label || v.label },
            })),
          );
        }
      },
      fetchPolicy: 'network-only',
    },
  );

  // Mutations
  const client = useApolloClient();
  const [upsertCustomFieldOption, { loading: createOptionFieldLoading }] = useMutation(
    MUTATION_UPSERT_CUSTOM_FIELD_OPTION,
    {
      onCompleted(data) {
        onLoading(false);
        client.writeFragment({
          id: `CustomField:${fieldDefinition.id}`,
          fragment: gql`
            fragment NewOptionCustomField on CustomField {
              options {
                __typename
                custom_field_id
                id
                label
                value
              }
            }
          `,
          data: {
            options: data.custom_field.options,
          },
        });
      },
      context: {
        batch: true,
      },
    },
  );

  // Hooks
  const prevLanguage = usePrevious(language);
  const prevParentValue = usePrevious(parentValue);
  const prevVisible = usePrevious(visible);
  const prevDisabled = usePrevious(disabled);
  const prevDisplay = usePrevious(display);
  const prevFieldDefinition = usePrevious(fieldDefinition);

  // Effects
  const change = useDebounce(values, isDebounced ? 400 : 0, null);

  useDidUpdateEffect(() => {
    handleSaveCustomFieldValues(change);
  }, [change]);

  useEffect(() => {
    // Overwrite the internal state with new prop values if the component is in view-only mode or if
    // the disabled value changes.
    if (disabled || disabled !== prevDisabled || prevDisplay !== 'field') {
      setValues(fieldValue.value || []);
    }
  }, [disabled, display, fieldValue.value]);

  useEffect(() => {
    if (fieldDefinition.option_src) {
      if (parentValue) {
        loadSelectOptions({
          PARENT: parentValue.value,
        });
      } else {
        loadSelectOptions();
      }
    }
  }, [disabled]);

  useDidUpdateEffect(() => {
    if (!visible && prevVisible) {
      // Reset the field if it became invisible
      setValues([]);
    }
  }, [visible]);

  useDidUpdateEffect(() => {
    if (prevParentValue && parentValue && prevParentValue.value !== parentValue.value) {
      loadSelectOptions({ PARENT: parentValue.value });
    }
  }, [parentValue]);

  useDidUpdateEffect(() => {
    if (
      prevLanguage !== language &&
      fieldDefinition.option_src &&
      fieldDefinition.option_src.includes('%LANGCODE%')
    ) {
      loadSelectOptions();
    }
  }, [language]);

  useDidUpdateEffect(() => {
    if (prevFieldDefinition.options !== fieldDefinition.options) {
      setOptions(fieldDefinition.options);

      cleanCurrentValues();
    }
  }, [fieldDefinition.options]);

  // Functions

  /**
   * @param {null|object|Array} changedValue
   * @returns {void}
   */
  function handleSaveCustomFieldValues(changedValue) {
    let _value = [];

    if (!changedValue) {
      // Is empty, because the user deselected everything
      _value = [];
    } else {
      _value = Array.isArray(changedValue) ? changedValue : [changedValue];
    }

    props.onUpdateCallback({
      key: fieldValue.key,
      value: _value,
    });
  }

  function loadSelectOptions(extra = {}) {
    if (!disabled && display === 'field') {
      onLoading(true);
      getCustomFieldOptions({
        variables: {
          input: {
            id: fieldDefinition.id,
            language,
            parent: extra.PARENT || null,
          },
        },
      });
    }
  }

  function cleanCurrentValues() {
    setValues(values.filter((v) => fieldDefinition.options.find((o) => o.value === v.value)));
  }

  function handleOnChange(selectedOptions) {
    if (fieldDefinition.multi && !selectedOptions.length) {
      setValues([]);
      return;
    }

    if (!fieldDefinition.multi && !selectedOptions) {
      setValues(null);
      return;
    }

    setValues(
      fieldDefinition.multi
        ? selectedOptions.map(getCustomFieldOptionValue)
        : [getCustomFieldOptionValue(selectedOptions)],
    );
  }

  function handleOnHideCreateOptions() {
    setCreateOptions([]);
  }

  /* Insert new dynamic option */
  async function handleOnCreateOptions(payload) {
    if (display === 'fieldPreview') {
      // Do nothing in the custom field settings page
      return;
    }

    onLoading(true);
    const promises = await Promise.all(
      payload.map(async (payloadItem) =>
        upsertCustomFieldOption({
          variables: {
            input: {
              id: fieldDefinition.id,
              ...payloadItem,
            },
          },
        }),
      ),
    );

    const newOptionsState = promises[promises.length - 1].data.custom_field.options;
    setOptions(newOptionsState);

    const newOptions = [...createOptions.filter((o) => !o.__isNew__), ...payload];
    setValues(
      fieldDefinition.multi
        ? [
            ...newOptionsState
              .filter((o) => newOptions.some((p) => p.value === o.value))
              .map((o) => getCustomFieldOptionValue(o)),
          ]
        : [getCustomFieldOptionValue(newOptionsState[newOptionsState.length - 1])],
    );
  }

  if (!visible) {
    return <div />;
  }

  switch (display) {
    case 'datatable': {
      return <CustomFieldValueLabels values={values} />;
    }
    case 'fieldValue': {
      return (
        <div className="space-2">
          <CustomFieldValueLabels values={values} />
        </div>
      );
    }
    default:
      return (
        <FormGroup
          data-testid={`custom-field-${fieldDefinition.name}-input`}
          controlId={fieldDefinition.name}
          className="custom-field"
        >
          <ControlLabel bsClass="control-label">
            {fieldDefinition.label}
            {fieldDefinition.important && !fieldDefinition.description && (
              <em className="icon-attention-alt text-warning" />
            )}
            {fieldDefinition.important && fieldDefinition.description && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`help-${fieldDefinition.name}`}>
                    Important! {fieldDefinition.description}
                  </Tooltip>
                }
              >
                <span className="icon-attention-alt text-warning" />
              </OverlayTrigger>
            )}
            {!fieldDefinition.important && fieldDefinition.description && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`help-${fieldDefinition.name}`}>
                    {fieldDefinition.description}
                  </Tooltip>
                }
              >
                <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
              </OverlayTrigger>
            )}
          </ControlLabel>
          {!fieldDefinition.dynamic_options ? (
            <Select
              name={fieldDefinition.name}
              inputId={`react-select-custom-field-${fieldDefinition.name}-input`}
              isMulti={!!fieldDefinition.multi}
              isDisabled={
                fieldDefinition.status === 'processing' || disabled || isCustomFieldOptionsLoading
              }
              isLoading={fieldDefinition.status === 'processing' || isCustomFieldOptionsLoading}
              isClearable
              isOptimizedRender
              value={values}
              options={options}
              components={selectComponents}
              onChange={handleOnChange}
            />
          ) : (
            <>
              <Select
                type="creatable"
                inputId={`react-select-custom-field-${fieldDefinition.name}-input`}
                name={fieldDefinition.name}
                isMulti={!!fieldDefinition.multi}
                isMultiCreatable
                isDisabled={
                  fieldDefinition.status === 'processing' || disabled || isCustomFieldOptionsLoading
                }
                isLoading={fieldDefinition.status === 'processing' || createOptionFieldLoading}
                placeholder="Add options"
                value={values}
                noOptionsMessage={({ inputValue }) => {
                  if (inputValue) {
                    return 'No results found';
                  }

                  return options.length === 0 ? 'Start typing to add your first option' : null;
                }}
                options={options}
                components={selectComponents}
                onChange={handleOnChange}
                onCreateOption={setCreateOptions}
                closeMenuOnSelect={false}
              />
              <SelectCreateNewOptions
                isShow={createNewOptions.length > 0}
                defaultLabels={createNewOptions.map((o) => o.label)}
                existingOptions={options}
                onCreate={handleOnCreateOptions}
                onHide={handleOnHideCreateOptions}
              />
            </>
          )}
        </FormGroup>
      );
  }
}

SelectFieldType.propTypes = propTypes;
SelectFieldType.defaultProps = defaultProps;

export default SelectFieldType;
